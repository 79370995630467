/* global wpAjax */

// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
// import './plugins/lazyload';
import './plugins/modernizr.min';
import './plugins/pagination';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
import AOS from 'aos';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */

$(document).on('ready', function () {
  /**
   * Min Price
   */
  let prices = [];
  $('.price-floor').each(function () {
    prices.push(parseFloat($(this).text().replace(',', '.')));
  });
  const minPrice = Math.min.apply(Math, prices);
  if (minPrice) {
    $('.floorplans__price span').text(minPrice);
  }

  /**
   * Floorplan on hover, slale up above everything else.
   */
  $('.floor-card__img').on('focus mouseenter', function () {
    $(this).parent('.floor-card').addClass('on-top');
    $(this).parent('.floor-card').siblings().removeClass('on-top');
  });

  /**
   * Copy link share
   */

  $('#copy').click(function () {
    var $temp = $('<input>');
    $('body').append($temp);
    $temp.val($('#textcopy').text()).select();
    document.execCommand('copy');
    $temp.remove();
  });

  /**
   * Filter floorplans
   */

  $('.filters-floor li a').click(function (event) {
    event.preventDefault();
    $(this).parent().addClass('current');
    $(this).parent().siblings().removeClass('current');
    let id = $(this).attr('href');
    let prices = [];

    $(id)
      .find('.price-floor')
      .each(function () {
        prices.push(parseFloat($(this).text().replace(',', '.')));
      });
    const minPrice = Math.min.apply(Math, prices);
    if (minPrice) {
      $(id).find('.floorplans__price span').text(minPrice);
    }

    $('.filters__lists').not(id).css('display', 'none');
    $(id).fadeIn();
  });

  /**
   * AJAX PAGINATION NEWS
   */

  function loadPaginationNews(value, paged) {
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: wpAjax.ajaxurl,
      data: {
        action: 'news_pagination_ajax',
        value: value,
        paged: paged,
      },
    }).done(function (response) {
      $('#ajax-news').html(response.template);
      if (response.total) {
        blogPaginationNews(value, response.total);
      }
    });
  }

  /**
   * CUSTOM PAGINATION
   */
  function blogPaginationNews(currentFilterNews, totalPagesNews) {
    $('#news-pagination').pagination({
      total: totalPagesNews,
      current: 1,
      length: 1,
      size: 2,
      prev: '<',
      next: '>',

      // fired on each click
      click: function (e) {
        loadPaginationNews(currentFilterNews, e.current);
      },
    });
  }

  $('.category-list__item').on('click', function () {
    $('.category-list__item').removeClass('active');
    $(this).addClass('active');
    loadPaginationNews($(this).data('category'), 1);
    blogPaginationNews(currentFilterNews, totalPagesNews);
  });

  // // Init Pagination
  let currentFilterNews = $('.category-list').find('.active').data('category');
  let totalPagesNews = parseInt($('#news-pagination').attr('data-max-pages'));
  blogPaginationNews(currentFilterNews, totalPagesNews);

  /**
   * AJAX PAGINATION GALLERY
   */

  function loadPaginationGallery(value, paged) {
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: wpAjax.ajaxurl,
      data: {
        action: 'gallery_pagination_ajax',
        value: value,
        paged: paged,
      },
    }).done(function (response) {
      $('#ajax-gallery').html(response.template);
      if (response.total) {
        blogPaginationGallery(value, response.total);
      }
    });
  }

  /**
   * CUSTOM PAGINATION
   */
  function blogPaginationGallery(currentFilterGallery, totalPagesGallery) {
    $('#gallery-pagination').pagination({
      total: totalPagesGallery,
      current: 1,
      length: 1,
      size: 2,
      prev: '<',
      next: '>',

      // fired on each click
      click: function (e) {
        loadPaginationGallery(currentFilterGallery, e.current);
      },
    });
  }

  $('.category-list__item').on('click', function () {
    $('.category-list__item').removeClass('active');
    $(this).addClass('active');
    loadPaginationGallery($(this).data('category'), 1);
    blogPaginationGallery(currentFilterGallery, totalPagesGallery);
  });

  // // Init Pagination
  let currentFilterGallery = $('.category-list')
    .find('.active')
    .data('category');
  let totalPagesGallery = parseInt(
    $('#gallery-pagination').attr('data-max-pages')
  );
  blogPaginationGallery(currentFilterGallery, totalPagesGallery);

  AOS.init({
    duration: 1200,
  });
  /**
   * Full Menu
   */
  $('.full-menu__close').on('click', function () {
    $('.full-menu').toggleClass('hide');
    $('.full-menu').removeClass('show');
    $('.top-header').removeClass('hide');
    $('.header').removeClass('hide');
  });
  $('.menu-icon').on('click', function () {
    $('.full-menu').toggleClass('show');
    $('.full-menu').removeClass('hide');
    $('.top-header').toggleClass('hide');
    $('.header').toggleClass('hide');
  });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Filter maps
 */
$(document).ready(function () {
  $('.filters-neig li a').click(function (event) {
    event.preventDefault();
    $(this).parent().addClass('current');
    $(this).parent().siblings().removeClass('current');
  });
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
